











































































































































































































































































































































































import {Component, Vue, Watch} from 'vue-property-decorator';
import firebase from 'firebase';
import {actionStringWork, Category, getterStringWork, IWork} from "@/store/work";
import {category, noMediaToolbar} from "@/Types/Types";
import {VueEditor } from "vue2-editor";
import {STORAGE} from "@/main";
import {Action, Getter} from "vuex-class";
import Loader from "@/components/loader.vue";
import {actionStringCalendarEvent, getterStringCalendarEvent, ICalendarEvent} from "@/store/calendarEvent";
import MultipleFileUploader from "@/components/MultipleFileUploader.vue";
import {actionStringImageGallery, IimageGallery} from "@/store/imageGallery";
import { actionStringPeople, getterStringPeople, IPeople } from '@/store/people';


@Component({
        components: {MultipleFileUploader, Loader, VueEditor},
    })

    export default class Profile extends Vue {
        @Action(actionStringWork.POST_WORK) postWork: ((work: IWork) => Promise<void>) | undefined;
        @Getter(getterStringWork.WORK) work: IWork[] | undefined;
        @Action(actionStringWork.GET_WORK) getWork: (() => Promise<IWork[]>) | undefined;
        @Action(actionStringWork.GET_WORK_BY_ID) getWorkById: ((workId: string) => Promise<IWork>) | undefined;
        @Action(actionStringWork.UPDATE_WORK) updateWork: ((work:IWork) => Promise<IWork>) | undefined;
        @Action(actionStringCalendarEvent.POST_CALENDAR_EVENT) postCalendarEvent: ((calendarEvent: ICalendarEvent) => Promise<void>) | undefined;
        @Getter(getterStringCalendarEvent.CALENDAR_EVENTS) calendarEvents: IWork[] | undefined;
        @Action(actionStringCalendarEvent.GET_CALENDAR_EVENTS) getCalendarEvents: (() => Promise<ICalendarEvent[]>) | undefined;
        @Action(actionStringCalendarEvent.UPDATE_CALENDAR_EVENT) updateCalendarEvent:((calendar:ICalendarEvent) => Promise<ICalendarEvent>) | undefined;
        @Action(actionStringCalendarEvent.GET_CALENDAR_EVENTS_BY_ID) getCalendarEventsById:((calendarId:string) => Promise<ICalendarEvent>) | undefined;
        @Action(actionStringCalendarEvent.DELETE_CALENDAR_EVENT) deleteCalendarEvent:((calendarId:string) => Promise<any>) | undefined;
        @Action(actionStringWork.DELETE_WORK_BY_ID) deleteWorkById:((workId:string) => Promise<any>) | undefined;
        @Action(actionStringWork.DELETE_GALLERY_BY_ID) deleteGalleryById:((id:string) => Promise<any>) | undefined;
        @Getter(getterStringPeople.PEOPLE) people!: IPeople[];
        @Action(actionStringPeople.GET_PEOPLE) getPeople: () => Promise<void>;
        @Action(actionStringPeople.POST_PEOPLE) postPeople: (newPeople: IPeople) => Promise<void>;
        @Action(actionStringPeople.DELETE_PEOPLE) deletePeople: (peopleId: string) => Promise<void>;
        @Action(actionStringPeople.UPDATE_PEOPLE) updatePeople: (updatedPeople: IPeople) => Promise<void>;

        @Action(actionStringImageGallery.POST_GALLERY_SLIDER) postGallerySlider: ((imageGallery: IimageGallery) => Promise<void>) | undefined;
        @Action(actionStringImageGallery.GET_GALLERY_SLIDER) getGallerySlider: (() => Promise<IimageGallery[]>) | undefined;



      // work:IWork[] = [];

        mediaToolBar:Array<any> = noMediaToolbar;
        gallerySliderTitle: string = "";
        technicalRider:any;
        loading:boolean = false;
        $router: any;
        isShowingWorkForm:boolean = false;
        localCategory:Category[] = category;
        selected:Category = Category.performances;
        selectedGallery:string = "";
        title:string = "";
        subTitle:string = "";
        content:any = null;
        previewImageUrl:string = "";
        workToBePosted:IWork | null = null;
        selectedIngressFile:File | null | Blob = null;
        selectedIngressFileName:string = "";
        selectedCategory:Category = Category.all;
        selectedCategoryFilter:Category = Category.blank;
        isEditingArticle:boolean = false;
        previewIngressImageUrl:string = "";
        currentEditingArticle:IWork | null = null;
        activeTab:number | null = null;
        isShowingCalendarForm:boolean = false;
        isShowingGalleryForm:boolean = false;
        isShowingNewPersonForm:boolean = false;
        isEditingPerson:boolean = false;
        currentEditingPerson:IPeople|null = null;
        calendarTitle:string = "";
        calendarDate:string = "";
        calendarText:any = null;
        isEditingEvent:boolean = false;
        currentEditingCalendarEvent:ICalendarEvent | null = null;
        currentEditingEventId:string = "";
        loadingProgress:number | null = null;
        markedForPreview:boolean = false;
        isEditingThumbnail:boolean = false
        gallerySlideList:IimageGallery[] = [];
        selectedRelatedArticles:any = [];
        priority:number = 0;


        addNew(){
            this.previewIngressImageUrl = "";
            this.isShowingWorkForm = true
            this.priority = 0
        }

  setCategory(category:Category):void{
    this.selectedCategory = category;
    this.selectedCategoryFilter = category;
  }

    async handleUploadSuccess(success: any){
        console.log("success ??", success);
        if(success){
            this.isShowingGalleryForm = false;
            await this.fetchGallerySlider();

        }
    }

    async handleImageUrls(imageUrls:any){
            console.log("iamgeurls", imageUrls)
        if(this.postGallerySlider)
        await this.postGallerySlider(imageUrls);
    }


  @Watch('currentEditingArticle.imageIngressUrl')
  runThis(oldImage: any, newImage: any){
    console.log("OLD", oldImage)
    console.log("NEW", newImage)

  }



  async updateArticle(article:IWork):Promise<void>{


        console.log("updated art", article);
        if(this.isEditingThumbnail){
          article.imageIngressUrl = await this.onUploadIngress();
        }


        if(this.updateWork){
            let updateWork = await this.updateWork(article);
            this.isEditingArticle = false;
          }
        }

      async updateEvent(event:ICalendarEvent):Promise<void>{
        console.log("EVENT", event);
        event.id = this.currentEditingEventId;
        this.loading = true;
        if(this.updateCalendarEvent){
          let updatedEvent = await this.updateCalendarEvent(event);
          this.loading = false
          this.currentEditingEventId = "";
          this.isEditingEvent = false;
        }
      }


        get selectedFilter():string{
            if(this.selectedCategory === Category.all){
                this.selectedCategoryFilter = Category.blank
            } else{
                this.selectedCategoryFilter = this.selectedCategory;
            }

            return this.selectedCategoryFilter;
        }

        async editArticle(workId:string):Promise<void>{



            this.previewIngressImageUrl = "";
            this.isEditingThumbnail = false;



            // this.previewImageUrl = "";
            this.isEditingArticle = true;


            if(this.getWorkById){
              let editingArticle = await this.getWorkById(workId);
              console.log("editingArticle", editingArticle)
              this.currentEditingArticle = editingArticle;
              // this.previewIngressImageUrl = this.currentEditingArticle.imageIngressUrl;
              //   this.imageIngressUrl
              this.selectedGallery = editingArticle.imageGallerySlider as string
              if(editingArticle?.relatedArticles.length > 0){
                  this.selectedRelatedArticles = await this.fetchWorkForIds(editingArticle?.relatedArticles)
              }

              console.log("Article to edit", editingArticle);
            }
        }

        async deleteEvent():Promise<void>{

          let deleteChoice = confirm("Are you sure you want to delete this article?");
          if (deleteChoice) {
            if(this.deleteCalendarEvent){
              let res = await this.deleteCalendarEvent(this.currentEditingEventId);
              this.isEditingEvent = false;
              console.log("local res del", res);
            }
          } else {

          }


        }


    async onPersonImageUpload(event) {
      const file = event.target.files[0];
      if (file) {
        try {
          let storageRef = STORAGE.ref();
          let uploadTask = storageRef.child("people/" + file.name).put(file);

          uploadTask.on('state_changed', (snapshot) => {
            let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
          }, (error) => {
            console.error("Error uploading the image:", error);
          }, async () => {
            let downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
            console.log('Person image available at', downloadURL);
            this.previewImageUrl = downloadURL;
          });
        } catch (error) {
          console.error("Error uploading person image:", error);
        }
      }
  }



      async editCalendarEvent(eventId:string):Promise<void>{
        console.log("ID", eventId);
        this.currentEditingEventId = eventId;

        if(this.getCalendarEventsById) {
          this.currentEditingCalendarEvent = await this.getCalendarEventsById(eventId);
          this.isEditingEvent = true;

        }



        // if(this.getWorkById){
        //   let editingCalendar = await this.getWorkById(eventId);
        //   this.currentEditingCalendarEvent = editingCalendar;
        //   console.log("Article to edit", editingCalendar);
        // }
      }

    handleFileUpload(event:any):void{
      this.technicalRider = event.target.files[0]
    }

    exitModal():void{
            this.isShowingWorkForm = false;
            this.clearForm();
    }

    async technicalRiderDownloadURL():Promise<any>{
            console.log(this.technicalRider)
            if(this.technicalRider !== undefined){
                let storageRef = STORAGE.ref();
                const fileRef = storageRef.child(this.technicalRider.name)
                await fileRef.put(this.technicalRider)
                return await fileRef.getDownloadURL()
            } else{
                return null;
            }
    }

        async onUploadIngress():Promise<any>{
            // Create a root reference
            return new Promise((resolve, reject) => {
                let storageRef = STORAGE.ref();


                let uploadTask = storageRef.child( "work/" + this.selectedIngressFileName).put(this.selectedIngressFile as any);


                uploadTask.on('state_changed', (snapshot:any) =>{
                    let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    this.loadingProgress = progress;
                    console.log('Upload is ' + progress + '% done');

                    switch (snapshot.state) {
                        case firebase.storage.TaskState.PAUSED: // or 'paused'
                            console.log('Upload is paused');
                            break;
                        case firebase.storage.TaskState.RUNNING: // or 'running'
                            console.log('Upload is running');
                            break;
                    }
                }, function(error) {
                    // Handle unsuccessful uploads
                    reject(error);
                }, () => {
                    console.log("inside snapshotref func");
                    uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) =>  {
                        console.log('File available at', downloadURL);
                        this.previewIngressImageUrl = downloadURL;
                        this.loadingProgress = null;
                        resolve(downloadURL);
                    });
                });

            });
        }

        onFileSelectIngress(event:any):void{
          console.log(event);
            this.selectedIngressFile = event.target.files[0];
            let filename = event.target.files[0].name;
            let date = new Date();
            date.toDateString();
            //Adding the date to get uniq name on photos.
            this.selectedIngressFileName = filename;
            this.selectedIngressFileName = this.selectedIngressFileName + date;
            if(filename.lastIndexOf('.')<= 0){
                return alert("please add a valid file");
            }
            const fileReader = new FileReader();
            fileReader.addEventListener('load', () => {
                this.previewIngressImageUrl = fileReader.result as string
            });
            fileReader.readAsDataURL(event.target.files[0]);
            this.previewIngressImageUrl = event.target.files[0]
            console.log("SET PREVIEW", this.previewIngressImageUrl);

           this.isEditingThumbnail = true;

        }

        clearForm():void{
            this.technicalRider = null;
            this.title = "";
            this.content = null;
            this.previewImageUrl = "";
            this.workToBePosted = null;
            this.selectedIngressFile = null;
            this.selectedIngressFileName = "";
            this.markedForPreview = false;
            this.selectedRelatedArticles = [];
            this.priority = 0;
        }

    async  fetchWorkForIds(Ids:Array<string>) {
        const responseArray = [];

        for (const id of Ids) {
            console.log("IDS", id)

            try {
                if(this.getWorkById){
                    const res = await this.getWorkById(id)
                    console.log("RES", res)
                    responseArray.push(res);
                }
            } catch (error) {
                console.error(`Error fetching data for ID ${id}:`, error);
            }
        }

        return responseArray;
    }




        async addWork():Promise<void>{
            let downloadURL = await this.onUploadIngress();
            let technicalRiderDownloadURL = await this.technicalRiderDownloadURL();

            this.workToBePosted = {
              category:this.selected,
              title:this.title,
              imageUrl:this.previewImageUrl,
              content:this.content,
              imageIngressUrl:downloadURL,
              markedForPreview:this.markedForPreview,
              rider:technicalRiderDownloadURL,
              imageGallerySlider:this.selectedGallery,
              relatedArticles: this.selectedRelatedArticles,
              priority: this.priority
            };

            if(this.postWork) {
                this.loading = true;
                let res = await this.postWork(this.workToBePosted);
                console.log("res promise", res);
                this.loading = false;
                this.isShowingWorkForm = false;
                this.previewIngressImageUrl = '';
                this.clearForm();
            }

            console.log("work", this.content);

        }
        handleImageAdded(file:any, Editor:any, cursorLocation:any) {

            return new Promise((resolve, reject) => {
                let storageRef = STORAGE.ref();
                console.log("file", file);

                let formData = new FormData();
                formData.append('image', file);
                console.log("formdata", formData);
                let uploadTask = storageRef.child( "work/" + file.name).put(file as any);
                uploadTask.on('state_changed', function(snapshot:any){
                    let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log('Upload is ' + progress + '% done');
                    switch (snapshot.state) {
                        case firebase.storage.TaskState.PAUSED: // or 'paused'
                            console.log('Upload is paused');
                            break;
                        case firebase.storage.TaskState.RUNNING: // or 'running'
                            console.log('Upload is running');
                            break;
                    }
                }, function(error) {
                    // Handle unsuccessful uploads
                    reject(error);
                }, () => {
                    console.log("inside snapshotref func");
                    // Handle successful uploads on complete
                    // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                    uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) =>  {
                        console.log('File available at', downloadURL);
                        this.previewImageUrl = downloadURL;
                        Editor.insertEmbed(cursorLocation, 'image', downloadURL);
                        resolve(downloadURL);
                    });
                });



            });

        }

        navigate():void{

        }

        async deleteArticle():Promise<void>{
          let txt;
          let deleteChoice = confirm("Are you sure you want to delete this article?");
          if (deleteChoice) {
            console.log("Pressed ok", this.currentEditingArticle?.id)
            if(this.deleteWorkById){
              let res = await this.deleteWorkById(this.currentEditingArticle?.id as string);
              this.isEditingArticle = false;

            }
          } else {
            console.log("You pressed Cancel")

          }
        }


    async deleteGallerySlider(id:string):Promise<void>{
        let txt;
        let deleteChoice = confirm("Are you sure you want to delete this slider?");
        if (deleteChoice) {

            if(this.deleteGalleryById){
                let res = await this.deleteGalleryById(id);
                await this.fetchGallerySlider();
            }
        } else {
            console.log("You pressed Cancel")
        }
    }

    async deletePerson(person:IPeople):Promise<void>{
      let deletePerson = confirm("Are you sure you want to delete this person?");
      if (deletePerson) {
        await this.deletePeople(person.id);
        this.isEditingPerson = false;
        if (person.image !== ''){
          const imageRef = STORAGE.refFromURL(person.image);
          await imageRef.delete();
        }
      }
    }

    openAddNewPersonBox() {
      this.previewImageUrl = ''
      this.isShowingNewPersonForm = true
      this.priority = this.people.length+1
    }
   
    openEditPersonBox(people: IPeople) {
      this.currentEditingPerson = people
      this.isEditingPerson = true
      this.previewImageUrl = this.currentEditingPerson.image || 'default.png'
    }

    async editPerson():promise <void> {
      this.currentEditingPerson.image = this.previewImageUrl || 'default.png'      

      if (this.currentEditingPerson.name === '') {
        alert('Missing name field')
      }
      else {
        await this.updatePeople(this.currentEditingPerson)
        this.isEditingPerson = false
        this.previewImageUrl = ''
      }
    }

    async addPerson() {
      let person = {
          name: this.title,
          profession: this.subTitle,
          description: this.content,
          image: this.previewImageUrl || 'default.png',
          priority: Number(this.priority)
        }
        if (person.name === ''){ alert('Missing name field') }
        else {
          await this.postPeople(person)
          this.isShowingNewPersonForm = false
        }
    }

      async addCalendarEvent():Promise<void>{
        let calendarEventToBePosted:ICalendarEvent = {title:this.calendarTitle, date:this.calendarDate, text:this.calendarText};
        if(this.postCalendarEvent){
          let res = await this.postCalendarEvent(calendarEventToBePosted);
          this.isShowingCalendarForm = false;
          console.log("the res", res);
        }
      }

    async addGallerySlider():Promise<void>{
        // let calendarEventToBePosted:ICalendarEvent = {title:this.calendarTitle, date:this.calendarDate, text:this.calendarText};
        // if(this.postCalendarEvent){
        //     let res = await this.postCalendarEvent(calendarEventToBePosted);
        //     this.isShowingCalendarForm = false;
        //     console.log("the res", res);
        // }
    }
    handleRelatedArticleChange():void{
      console.log("Running..", this.selectedRelatedArticles)
        if(this.currentEditingArticle){
            this.currentEditingArticle.relatedArticles = this.selectedRelatedArticles;
        }

        console.log("Running..", this.currentEditingArticle)

    }

    handleGalleryChange():void{
      console.log(this.selectedGallery);

      if(this.currentEditingArticle){
        this.currentEditingArticle.imageGallerySlider = this.selectedGallery;
      }
    }

    async fetchGallerySlider():Promise<void>{
        if (this.getGallerySlider) {
            this.loading = true;
            try {
                const res = await this.getGallerySlider();
                this.loading = false;
                //
                // // Create a copy of the res array and sort the copy
                // const sorted = [...res].sort((a: any, b: any) => {
                //     const dateA: any = new Date(a.created.seconds * 1000 + a.created.nanoseconds / 1000000);
                //     const dateB: any = new Date(b.created.seconds * 1000 + b.created.nanoseconds / 1000000);
                //     return dateB - dateA;
                // });


                this.gallerySlideList = res;

                console.log("wtf", this.gallerySlideList)




            } catch (err) {
                this.loading = false;
            }
        }
    }


        async created(): Promise<void> {

            this.activeTab = 1;

            firebase.auth().onAuthStateChanged((user) => {
                if (user) {
                    // User is signed in, see docs for a list of available properties
                    console.log("USER IS LOGGED IN");
                    let uid = user.uid;
                    // ...
                } else {
                    // User is signed out
                    console.log("USER IS NOT LOGGED IN");
                    this.$router.push("/login");

                }
            });

            // FETCH PEOPLE DB
            if (this.getPeople) {
                this.loading = true;
                this.getPeople().catch(err => {
                  console.error('Error fetching people:', err);
                });
                this.loading = false;
            }

            if (this.getWork) {
                this.loading = true;
                this.getWork().then(res => {
                    // this.work = res;
                    this.loading = false;
                }).catch(err => {
                    this.loading = false;
                })
            }

            if (this.getCalendarEvents) {
                this.loading = true;
                this.getCalendarEvents().then(res => {
                    this.loading = false;
                }).catch(err => {
                    this.loading = false;
                })
            }

            await this.fetchGallerySlider();


        }
    }

